import { Userpilot } from 'userpilot'

import { IdentifiedUser, AnalyticsTrackEvent } from '@src/analytics'

import AppConfig from '../AppConfig'

import { Service } from './index'

type UserPilot = typeof Userpilot

declare global {
  interface Window {
    userpilot?: UserPilot
  }
}

const userpilot: Service<UserPilot> = {
  async init() {
    if (AppConfig.USER_PILOT_TOKEN) {
      Userpilot.initialize(AppConfig.USER_PILOT_TOKEN)
    }
  },

  loaded() {
    return window.userpilot !== undefined
  },
  instance() {
    return window.userpilot
  },
  identify(user: IdentifiedUser) {
    if (AppConfig.USER_PILOT_TOKEN) {
      Userpilot.identify(user?.id, {
        id: user?.id,
        email: user?.email,
        name: user?.name,
      })
    }
  },
  track(details: AnalyticsTrackEvent) {
    // user pilot accepts meta data but it doesn't appear to be very useful in distinguishing events
    const { type, data } = details
    const eventName = [type]
    if (data?.name) {
      eventName.push(String(data.name))
    }
    if (AppConfig.USER_PILOT_TOKEN) {
      Userpilot.track(eventName.join(':'), details)
    }
  },
  page() {
    // user pilot docs specify we need to reload on page change
    // https://docs.userpilot.com/article/21-install-userpilot-on-your-web-app
    if (AppConfig.USER_PILOT_TOKEN) {
      Userpilot.reload()
    }
  },
}
export default userpilot
