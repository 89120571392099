//Basic app wide actions we want to share
import I18n from 'i18n-js'
import _ from 'lodash'
import { combineEpics } from 'redux-observable'

import normalizeLocaleName from '@src/lib/normalizeLocaleName'
export const APP_ERROR = 'APP_ERROR'

//Basic CreateReducer...
export const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => {
    if (handlers && handlers[action.type]) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

export const combineEpicsFromImport = (epics) => {
  let epicValues = []
  Object.keys(epics).forEach((key) => {
    epicValues.push(epics[key])
  })

  return combineEpics(...epicValues)
}

export const currencyFormatter = (precision = 2) => {
  const locale = typeof navigator === 'object' ? navigator.language : 'en-US'
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: precision,
  })
}

export const formatWithCents = currencyFormatter(2)
const _formatWithoutCents = currencyFormatter(0)
export const formatWithoutCents = {
  format: (value) => {
    // If the value is a whole number, skip displaying the cents.
    if (value % 1 === 0) {
      return _formatWithoutCents.format(value)
    }

    return formatWithCents.format(value)
  },
}

export const numberFormatter = (precision = 0) => {
  const locale = typeof navigator === 'object' ? navigator.language : 'en-US'
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: precision,
  })
}

export const createR1UrlBasePath = (retailerSlug, brandSlug) => {
  return `/${retailerSlug}/${brandSlug}/`
}

export const isEqualWith = (a, b) => {
  return _.isEqualWith(a, b, (val1, val2) => {
    if (_.isFunction(val1) && _.isFunction(val2)) {
      return val1.toString() === val2.toString()
    }
    return val1 === val2
  })
}

export const campaignMinimumSpend = (starts, ends) => {
  const durationInDays =
    (starts && ends
      ? Math.ceil((new Date(ends) - new Date(starts)) / 86400000)
      : 1) + 1 // force +1 day to account for BE adding an additional day
  return durationInDays * 1 // 1 dollar is the minimum spend per day
}

// copied from brand for consistency
// return a date string or date in a standard format for the app
export const formatDate = (input) => {
  const date =
    typeof input === 'string' || input instanceof String
      ? new Date(input)
      : input

  const formatter = new Intl.DateTimeFormat(normalizeLocaleName(I18n.locale), {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  })
  return formatter
    .format(date)
    .replace(',', '')
    .replace(/AM|PM/, (match) => match.toLowerCase())
}

export const formatDateforDateTimeInput = (input) => {
  const date = new Date(input)
  if (isNaN(date.getTime())) return ''

  const formatter = new Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  return formatter.format(date).replace(', ', 'T').replace(/\//g, '-')
}
