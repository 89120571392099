// import { AnalyticsBrowser } from '@segment/analytics-next'

import AppConfig from '@src/config/AppConfig'
import services from '@src/config/services'
export interface Analytics {
  identify: (user: IdentifiedUser) => void
  track: (event: AnalyticsTrackEvent) => void
  setCommonInfo: (info: UnknownObject) => void
  page: (event: AnalyticsPageEvent) => void
  reset: () => void
}

export interface AnalyticsPageEvent extends UnknownObject {
  type: 'Page View'
}

export interface AnalyticsTrackEvent extends UnknownObject {
  event: 'Click' | 'View' | 'Event' | 'Login' | 'Action'
  type: string
  data?: UnknownObject
}

export interface UnknownObject {
  [key: string]: unknown
}

export interface IdentifiedUser {
  id: string
  email: string
  createdAt: string
  type: string
  env: string
  app: string
  uuid: string
  timeZone: string
  timeZoneAbbr: string
  name: string
  firstName: string
  lastName: string
  languagePreference: string
  roles: string[]
  industryList: string[]
  brandNames: string[]
  brandIds: string[]
  retailerAccountNames: string[]
  retailerAccountIds: string[]
  retailerIds: string[]
}

let commonInfo: UnknownObject = {}

const analytics: Analytics = {
  identify(user: IdentifiedUser) {
    services.identify(user)

    if (process.env.NODE_ENV === 'development' || AppConfig.DEBUG_ANALYTICS) {
      console.info('[analytics] [identify]', user)
    }
  },

  track(event) {
    const mergedEvent = {
      ...event,
      ...commonInfo,
    }

    services.track(mergedEvent)

    if (process.env.NODE_ENV === 'development' || AppConfig.DEBUG_ANALYTICS) {
      console.info('[analytics] [track]', mergedEvent)
    }
  },

  page(event) {
    const mergedEvent = {
      ...event,
      ...commonInfo,
    }

    services.page(mergedEvent)

    if (process.env.NODE_ENV === 'development' || AppConfig.DEBUG_ANALYTICS) {
      console.info('[analytics] [page]', mergedEvent)
    }
  },

  setCommonInfo(info) {
    commonInfo = info
  },

  reset() {
    window.analytics?.reset()
  },
}

export default analytics
